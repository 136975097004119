<template>
    <div class="container">
        <el-tabs v-model="activeName">
            <el-tab-pane label="个人信息" name="first">
                <el-form ref="infoForm" :model="infoForm" :rules="infoFormRules" label-width="80px">
                    <el-form-item label="头像" prop="avatar">
                        <tb-image action="resource.upload.image"
                                  :images="infoForm.avatar ? [infoForm.avatar] : []" :count="1"
                                  @remove="() => this.infoForm.avatar = null"
                                  @uploaded=" data => this.infoForm.avatar = data.src"/>
                    </el-form-item>
                    <el-form-item label="昵称" prop="nickname">
                        <el-input v-model="infoForm.nickname" clearable placeholder="请输入昵称"/>
                    </el-form-item>
                    <el-form-item label="账号" prop="account">
                        <el-input v-model="infoForm.account" readonly clearable placeholder="请输入昵称"/>
                    </el-form-item>
                    <el-form-item label="性别" prop="gender">
                        <el-radio-group v-model="infoForm.gender">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doUpdateInfo" :loading="loading">确 认 修 改</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="second">
                <el-form ref="passForm" :model="passForm" :rules="passFormRules" label-width="80px">
                    <el-form-item label="登录密码" prop="password">
                        <el-input type="password" v-model="passForm.password" clearable show-password
                                  placeholder="请输入登录密码"/>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirm">
                        <el-input type="password" v-model="passForm.confirm" show-password clearable
                                  placeholder="请再次输入密码"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doUpdatePass" :loading="loading">确 认 修 改</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: "Info",
    data: function () {
        return {
            activeName: 'first',
            loading: false,
            infoForm: {
                id: null,
                avatar: null,
                nickname: null,
                account: null,
                gender: 1
            },
            infoFormRules: {
                nickname: [
                    {required: true, message: '请输入昵称', trigger: 'blur'},
                ]
            },
            passForm: {
                password: null,
                confirm: null
            },
            passFormRules: {
                password: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                ],
                confirm: [
                    {required: true, message: '请再次输入密码', trigger: 'blur'},
                ]
            },
        }
    },
    created: function () {
        this.infoForm = Object.assign(this.infoForm, this.$store.state.user)
    },
    methods: {
        doUpdateInfo: function () {
            this.$refs.infoForm.validate(valid => {
                if (!valid) return
                this.$helper._post(this, 'admin.edit', this.infoForm, resp => {
                    this.$message.success(resp.message)
                    this.updateInfo()
                })
            })
        },
        updateInfo: function () {
            this.$helper._get(this, 'admin.info', {}, resp => {
                this.$store.commit('updateUser', resp.data)
            })
        },
        doUpdatePass: function () {
            this.$refs.passForm.validate(valid => {
                if (!valid) return
                this.$helper._post(this, 'admin.pass', this.passForm, resp => {
                    this.$message.success(resp.message)
                    setTimeout(() => {
                        this.$helper.removeSession('__TOKEN__')
                        this.$helper.removeSession('menus')
                        this.$router.replace("/", () => {
                        });
                    }, 1500)
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
